
import { InstagramApi } from '@/api/InstagramApi';
import { FB_LOGIN_SCOPE, GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { AppRoute } from '@/router/routes';
import { SelectOption } from '@/types';
import { copyToClipboard } from '@/utilities';
import { FacebookPage } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface FacebookLoginStatusResponse {
  status: 'connected' | 'not_authorized' | 'unknown';
  authResponse?: FacebookAuthResponse;
}

interface FacebookLoginOptions {
  auth_type?: 'rerequest' | 'reauthenticate' | 'reauthorize';
  scope: string;
  return_scopes?: boolean;
  enable_profile_selector?: boolean;
  profile_selector_ids?: string;
}

interface FacebookLoginResponse {
  authResponse?: FacebookAuthResponse;
}

interface FacebookAuthResponse {
  accessToken: string;
  expiresIn: number;
  data_access_expiration_time: number;
  graphDomain: string;
  signedRequest: string;
  userID: string;
  grantedScopes?: string;
}

declare global {
  interface Window {
    FB: {
      [key: string]: any;
      init(params: Record<string, any>): void;
      getLoginStatus(cb: (response: FacebookLoginStatusResponse) => void | Promise<void>): void;

      login(
        cb: (response: FacebookLoginResponse) => void | Promise<void>,
        options: FacebookLoginOptions,
      ): void;
      logout(cb: () => void | Promise<void>): void;

      getAuthResponse(): FacebookAuthResponse | null;
    };
  }
}

const SCOPE = [
  ...FB_LOGIN_SCOPE,
  'instagram_basic',
  'instagram_manage_messages',
  'instagram_content_publish',
];

@Component({
  name: 'instagram-login-card',
})
export default class InstagramLoginCard extends Vue {
  @Prop({ required: true, type: String })
  readonly botId!: string;

  @Prop({ required: true, type: String })
  readonly pageId!: string;

  @Prop({ required: true, type: String })
  readonly businessAccountId!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hideCopyButton!: boolean;

  @Prop({ required: false, type: String, default: 'Connect to page' })
  readonly header!: string;

  loadedPages: FacebookPage[] = [];

  selectedPageId = '';

  isLoading = false;
  isSubmitting = false;
  isDisconnecting = false;

  async beforeCreate() {
    window.FB.init({
      appId: this.$tenant.facebookAccountId || '',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v9.0',
    });
  }

  async created() {
    window.FB.getLoginStatus(async (response) => {
      if (response.status === 'connected' && response.authResponse) {
        await this.loadPages(response.authResponse);
      }
    });
  }

  get canSave(): boolean {
    return !!this.selectedPage;
  }

  get canDisconnect(): boolean {
    return !!this.pageId;
  }

  get hasChanges(): boolean {
    return this.selectedPageId !== this.pageId;
  }

  get selectedPage(): FacebookPage | undefined {
    return this.loadedPages.find((page) => page.id === this.selectedPageId);
  }

  get pageOptions(): SelectOption[] {
    return this.loadedPages.map((page) => ({
      value: page.id,
      label: page.name,
    }));
  }

  onLogin(event: MouseEvent) {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          (async () => {
            try {
              await this.loadPages(response.authResponse!);
            } catch (error) {
              console.error('Error in async operation:', error);
            }
          })();
        }
      },
      {
        scope: SCOPE.join(','),
        // eslint-disable-next-line
        enable_profile_selector: true,
      },
    );
  }

  async onSubmit() {
    const authResponse = window.FB.getAuthResponse();
    if (!this.selectedPage || !authResponse) return;
    this.isSubmitting = true;
    try {
      const instagramPlatformData = await InstagramApi.connectBotWithInstagram(this.botId, {
        pageId: this.selectedPage.id,
        userAccessToken: authResponse.accessToken,
        userId: authResponse.userID,
      });
      this.$emit('connect', instagramPlatformData);
      this.$notify.success('Successfully connected to Instagram');
    } catch (e) {
      this.$notify.error({
        title: 'Connecting to Instagram failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  async onDisconnect() {
    if (!this.canDisconnect) return;
    this.isDisconnecting = true;
    try {
      await InstagramApi.disconnectBotFromInstagram(this.botId);
      this.$emit('disconnect');
      this.$notify.success('Successfully disconnected from Instagram');
    } catch (e) {
      this.$notify.error({
        title: 'Disconnecting from Instagram failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isDisconnecting = false;
    }
  }

  copyPublicLoginUrl() {
    const resolvedRoute = this.$router.resolve({
      name: AppRoute.PublicSocialLoginInstagram,
      params: { id: this.botId },
    });
    copyToClipboard(location.origin + resolvedRoute.href);
  }

  async loadPages(authResponse: FacebookAuthResponse) {
    this.isLoading = true;
    try {
      this.loadedPages = await InstagramApi.loadFacebookPagesWithConnectedInstagramAccounts(
        authResponse.userID,
        authResponse.accessToken,
      );
      if (this.loadedPages.length === 0 && this.hasChanges) {
        this.reset();
      }
    } catch (e) {
      this.$notify.error({
        title: 'Loading Instagram-pages failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }

  @Watch('pageId', { immediate: true })
  reset() {
    this.selectedPageId = this.pageId;
  }
}
