
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { LinkedInPlatformData, SupportedPlatform } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type LinkedInSettings = Pick<LinkedInPlatformData, 'enablePosting' | '_id' | 'type'>;

@Component({
  name: 'linked-in-settings-card',
})
export default class LinkedInSettingsCard extends mixins(BotMixin) {
  linkedInSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.linkedInSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBotPlatform({
        id: this.bot.id,
        platform: SupportedPlatform.LinkedIn,
        data: this.linkedInSettings,
      });
      this.$notify.success('Successfully updated LinkedIn-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating LinkedIn-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.linkedInSettings = this.resetValue();
  }

  private resetValue(): LinkedInSettings {
    return {
      enablePosting: this.bot.platformData.linkedIn.enablePosting,
      _id: this.bot.platformData.linkedIn._id,
      type: this.bot.platformData.linkedIn.type,
    };
  }
}
