
import LinkedInSettingsCard from '@/components/linked-in/LinkedInSettingsCard.vue';
import LinkedInLoginCard from '@/components/linked-in/LinkedInLoginCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { Bot, LinkedInPlatformData, SupportedPlatform } from 'ignite360-core';
import _merge from 'lodash.merge';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-social-platform-linked-in',
  components: { LinkedInLoginCard, LinkedInSettingsCard },
})
export default class ProjectSocialPlatformLinkedIn extends mixins(ProjectViewMixin) {
  onConnect(data: Partial<LinkedInPlatformData>) {
    const newBot: Bot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.LinkedIn]: data,
      },
    });
    this.$store.commit('bots/updateBot', newBot);
  }

  onDisconnect() {
    const newBot: Bot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.LinkedIn]: {
          _id: '',
          type: '',
        },
      },
    });
    this.$store.commit('bots/updateBot', newBot);
  }
}
