import { BASE_URL } from '@/api/index';
import axios, { AxiosRequestConfig } from 'axios';
import {
  FacebookPage,
  SubscribeFacebookPagePayload,
  SubscribeFacebookPageResult,
  SubscribeInstagramPageResponse,
} from 'ignite360-core';

export class InstagramApi {
  static async loadFacebookPagesWithConnectedInstagramAccounts(
    facebookUserId: string,
    userAccessToken: string,
  ): Promise<FacebookPage[]> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/instagram/pages`,
      data: {
        facebookUserId,
        accessToken: userAccessToken,
      },
    };
    const result = await axios.request<FacebookPage[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    return [];
  }

  static async connectBotWithInstagram(
    botId: string,
    data: SubscribeFacebookPagePayload,
  ): Promise<SubscribeInstagramPageResponse> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${botId}/instagram/subscribe`,
      data,
    };

    const result = await axios.request<SubscribeInstagramPageResponse>(config);
    if (result.status === 200) {
      return result.data;
    }
    throw new Error('Could not connect bot to instagram!');
  }

  static async disconnectBotFromInstagram(botId: string) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${botId}/instagram/unsubscribe`,
    };

    const result = await axios.request<SubscribeFacebookPageResult>(config);
    if (result.status === 204) {
      return;
    }
    throw new Error('Could not disconnect bot from instagram!');
  }

  static async publishTestPost(
    id: string,
    params?: { pageId: string; pageAccessToken: string; businessAccountId: string },
  ): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${id}/instagram/publish-listing`,
      data: params,
    };

    const result = await axios.request(config);
    if (result.status === 204) {
      return;
    }
    throw new Error('Could not publish post.');
  }
}
