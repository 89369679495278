
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { InstagramPlatformData, SupportedPlatform } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type InstagramSettings = Pick<
  InstagramPlatformData,
  | 'enableMessaging'
  | 'enablePosting'
  | 'pageId'
  | 'pageAccessToken'
  | 'pageName'
  | 'businessAccountId'
>;

@Component({
  name: 'instagram-settings-card',
})
export default class InstagramSettingsCard extends mixins(BotMixin) {
  instagramSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.instagramSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBotPlatform({
        id: this.bot.id,
        platform: SupportedPlatform.Instagram,
        data: this.instagramSettings,
      });
      this.$notify.success('Successfully updated Instagram-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Instagram-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.instagramSettings = this.resetValue();
  }

  private resetValue(): InstagramSettings {
    return {
      enableMessaging: this.bot.platformData.instagram.enableMessaging,
      enablePosting: this.bot.platformData.instagram.enablePosting,
      pageId: this.bot.platformData.instagram.pageId,
      pageAccessToken: this.bot.platformData.instagram.pageAccessToken,
      pageName: this.bot.platformData.instagram.pageName,
      businessAccountId: this.bot.platformData.instagram.businessAccountId,
    };
  }
}
