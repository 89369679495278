
import { LinkedInApi } from '@/api';
import { GENERIC_ERROR_DESCRIPTION, tenant } from '@/constants';
import { AppRoute } from '@/router/routes';
import { SelectOption } from '@/types';
import { copyToClipboard } from '@/utilities';
import { LinkedInPage, LinkedInPlatformData } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'linked-in-login-card',
})
export default class LinkedInLoginCard extends Vue {
  @Prop({ required: true, type: String })
  readonly botId!: string;

  @Prop({ required: true, type: String })
  readonly pageId!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hideCopyButton!: boolean;

  @Prop({ required: false, type: String, default: 'Connect to page' })
  readonly header!: string;

  selectedPageId = '';

  loadedPages: LinkedInPage[] = [];

  isLoading = false;
  isSubmitting = false;
  isDisconnecting = false;

  get canSave(): boolean {
    return !!this.selectedPageId;
  }

  get canDisconnect(): boolean {
    return !!this.pageId;
  }

  get hasChanges(): boolean {
    return this.selectedPageId !== this.pageId;
  }

  get pageOptions(): SelectOption[] {
    return this.loadedPages.map((page) => ({
      value: page._id,
      label: page.name,
      image: page.logo,
    }));
  }

  get selectedPage(): LinkedInPage | undefined {
    return this.loadedPages.find((page) => page._id === this.selectedPageId);
  }

  async loadOrganizations() {
    const organizations = await LinkedInApi.loadOrganizations(this.botId);
    this.loadedPages.push(...organizations);
  }

  async loadProfile() {
    const profile = await LinkedInApi.loadLinkedInProfile(this.botId);
    this.loadedPages.push(profile);
  }

  onLogin() {
    const baseUrl = tenant.apiBaseUrl || 'http://localhost:4000';
    window.location.href = `${baseUrl}/public/linked-in/auth/login?bot_id=${this.botId}&redirect=${window.location.href}`;
  }

  async onSubmit() {
    if (!this.selectedPage) return;
    this.isSubmitting = true;
    try {
      await LinkedInApi.connectBotWithLinkedInPage(this.botId, this.selectedPage);
      const linkedInPlatformData: Partial<LinkedInPlatformData> = {
        _id: this.selectedPage._id,
        type: this.selectedPage.type,
      };
      this.$emit('connect', linkedInPlatformData);
      this.$notify.success('Successfully connected to LinkedIn');
    } catch (e) {
      this.$notify.error({
        title: 'Connecting to LinkedIn failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  async onDisconnect() {
    if (!this.canDisconnect) return;
    this.isDisconnecting = true;
    try {
      await LinkedInApi.disconnectBotFromLinkedInPage(this.botId);
      this.$emit('disconnect');
      this.$notify.success('Successfully disconnected from LinkedIn');
    } catch (e) {
      this.$notify.error({
        title: 'Disconnecting from LinkedIn failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isDisconnecting = false;
    }
  }

  copyPublicLoginUrl() {
    const resolvedRoute = this.$router.resolve({
      name: AppRoute.PublicSocialLoginLinkedIn,
      params: { id: this.botId },
    });
    copyToClipboard(location.origin + resolvedRoute.href);
  }

  @Watch('pageId', { immediate: true })
  reset() {
    this.selectedPageId = this.pageId;
  }

  @Watch('botId', { immediate: true })
  async onBotChange() {
    this.loadedPages = [];
    this.isLoading = true;
    try {
      await Promise.all([this.loadProfile(), this.loadOrganizations()]);
    } catch (e) {
      // ignore the errors for LinkedIn
    } finally {
      this.isLoading = false;
    }
  }
}
